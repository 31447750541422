import * as PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectRoomByCode } from './slice';

const TableRow = ({ code }) => {
    const room = useSelector(state => selectRoomByCode(state, code));

    return (
        <tr>
            <td>
                {room.property_code}
            </td>
            <td>
                <Link to={`/owners/${room.owner_code}`}>{room.owner_code}</Link>
            </td>
            <td>
                {room.room_number}
            </td>
        </tr>
    );
};

TableRow.propTypes = {
    code: PropTypes.string.isRequired
};

export default TableRow;
