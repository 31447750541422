import { faFileDownload } from '@fortawesome/free-solid-svg-icons/faFileDownload';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { useDispatch } from 'react-redux';
import ErrorModal from '../errors/ErrorModal';
import { generateXlsxAttachment } from './xlsxsSlice';

const XlsxWorkbookCell = ({ accountId, ownerId, period }) => {
  const [error, setError] = useState(false);
  const dispatch = useDispatch();

  const handleError = error => {
    setError(error);
  };

  const handleDownloadFile = useCallback(() => {
    dispatch(generateXlsxAttachment({
      accountId,
      period,
      fileName: `${period}.xlsx`,
      handleError,
      params: {
        owner_id: ownerId
      }
    }));
  }, [dispatch, accountId, ownerId, period]);

  const handleErrorModalHide = useCallback(() => {
    setError(false);
  }, []);

  return (
    <td>
      <Button variant="secondary" className="mr-1" onClick={handleDownloadFile}>
        <FontAwesomeIcon icon={faFileDownload} /> 作成
      </Button>

      <ErrorModal onHide={handleErrorModalHide} error={error} />
    </td>
  );
};

XlsxWorkbookCell.propTypes = {
  ownerId: PropTypes.number.isRequired,
  accountId: PropTypes.number.isRequired,
  period: PropTypes.string.isRequired
};

export default XlsxWorkbookCell;
