import isEqual from 'lodash.isequal';

/**
 * Difference between two object
 * @param  {Object} source Object to compare with
 * @param  {Object} target Object compared
 * @return {Object}        Return a new object who represent the diff
 */
export default function objectDifference(source, target) {
  const changes = {};
  let hasChanged = false;

  Object.entries(target).forEach(([key, value]) => {
    if (!isEqual(source[key], value)) {
      changes[key] = value;

      hasChanged = true;
    }
  });

  return hasChanged ? changes : hasChanged;
}
