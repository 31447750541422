import { combineReducers } from '@reduxjs/toolkit';
import { connectRouter, LOCATION_CHANGE } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import loading from '../common/loadingSlice';
import accounts from '../features/accounts/accountsSlice';
import pdfs from '../features/attachments/pdfsSlice';
import xlsxs from '../features/attachments/xlsxsSlice';
import error from '../features/errors/errorSlice';
import owners from '../features/owners/ownersSlice';
import statements from '../features/statements/statementsSlice';
import properties from '../features/properties/slice';
import rooms from '../features/rooms/slice';
import session from '../features/session/sessionSlice';

export const history = createBrowserHistory();

const allReducers = combineReducers({
    router: connectRouter(history),
    session,
    owners,
    accounts,
    statements,
    properties,
    rooms,
    attachments: combineReducers({ pdfs, xlsxs }),
    loading,
    error
});

const rootReducer = (state, action) => {
    if (action.type === LOCATION_CHANGE) {
        const { session, router } = state;
        state = { session, router };
    }

    return allReducers(state, action);
};

export default rootReducer;
