import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';
import PrivateRoute from '../enhancers/PrivateRoute';
import Layout from '../features/layout/Layout';
import { initialize, selectSessionIsLoading } from '../features/session/sessionSlice';
import history from './history';
import routes from './routes';

const App = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectSessionIsLoading);

  useEffect(() => {
    dispatch(initialize());
  }, [dispatch]);

  if (isLoading) {
    console.log('App#renderNull');
    return null;
  }

  console.log('App#render');
  return (
    <Router history={history}>
      <Switch>
        {routes.map(route => {
          if (route.isPrivate) {
            return <PrivateRoute key={route.path} {...route} />;
          } else {
            return <Layout key={route.path} {...route} />;
          }
        })}
        <Route render={() => <pre>No url</pre>} />
      </Switch>
    </Router>
  );
};

export default App;
