import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { createLoadingSelector } from '../../common/loadingSlice';
import * as tokenUtils from "../../utils/token";
import { fetchInfo } from './sessionApi';

// Operations
export const initialize = createAsyncThunk(
  'session/initialize',
  async (_, { rejectWithValue }) => {
    try {
      const token = tokenUtils.getToken();

      if (token) {
        const user = await fetchInfo();

        return user;
      }

      return;
    } catch (error) {
      console.error(error.message);

      return rejectWithValue(error.message);
    }
  }
);

export const login = createAsyncThunk(
  'session/login',
  async ({ username, password }, { rejectWithValue }) => {
    try {
      await tokenUtils.authenticate(username, password);

      const user = await fetchInfo();

      return user;
    } catch (err) {
      // Set error key depending on error name
      let error = 'generic';
      if (err.name === 'OAuth2Error') {
        error = 'invalid_credentials';
      }

      return rejectWithValue(error);
    }
  }
);

// Slice
const sessionSlice = createSlice({
  name: 'session',
  initialState: {
    isLoading: true,
    isAuthenticated: false,
    user: {},
    redirectUrl: null,
    error: false
  },
  reducers: {
    logout: state => {
      tokenUtils.removeToken();

      state.isAuthenticated = false;
      state.user = {};
    },
    setRedirect: (state, action) => {
      state.redirectUrl = action.payload;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(initialize.fulfilled, (state, action) => {
        state.isLoading = false;

        if (action.payload) {
          state.user = action.payload;
          state.isAuthenticated = true;
        }
      })
      .addCase(initialize.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(login.pending, state => {
        state.error = false;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.user = action.payload;
        state.isAuthenticated = true;
      })
      .addCase(login.rejected, (state, action) => {
        state.error = action.payload;
      });
  }
});

// Actions
export const { logout, setRedirect } = sessionSlice.actions;

// Selectors
export const selectSession = state => state.session;
export const selectSessionIsAuthenticated = createSelector([selectSession], session => session.isAuthenticated);
export const selectSessionUser = createSelector([selectSession], session => session.user);
export const selectSessionIsLoading = createSelector([selectSession], session => session.isLoading);
export const selectSessionIsAuthenticating = createLoadingSelector([login.typePrefix]);

// Reducer
export default sessionSlice.reducer;
