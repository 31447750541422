import config from '../../app/config';
import authRequest from '../../utils/request';

export const fetchRoomListRequest = ({ params }) => authRequest({
  url: `${config.api.url}/rooms`,
  params
});

export const insertRoomRequest = ({ data }) => authRequest({
  url: `${config.api.url}/rooms`,
  method: 'post',
  data,
});
