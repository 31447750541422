import createCachedSelector from 're-reselect';
import { useSelector } from 'react-redux';

const selectLoadingByAction = (state, action) => state.loading[action];
const selectErrorByAction = (state, action) => state.error[action];

const createAsyncThunkFlagsSelector = createCachedSelector(
  selectLoadingByAction,
  selectErrorByAction,
  (loading, error) => ({
    isLoading: loading ? loading : false,
    error: error ? error : null
  })
)(
  (_, action) => action
);

const useAsyncThunkFlagsSelector = operation => useSelector(state => createAsyncThunkFlagsSelector(state, operation.typePrefix));

export default useAsyncThunkFlagsSelector;
