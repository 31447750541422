import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { normalize } from 'normalizr';
import { propertySchema } from '../../app/schema';
import { fetchPropertyListRequest, fetchPropertyRequest, insertPropertyRequest, updatePropertyRequest } from './api';

// Operations
export const fetchPropertyList = createAsyncThunk(
  'properties/fetchPropertyList',
  async (options = {}, { rejectWithValue }) => {
    try {
      const { params = {} } = options;

      const propertyList = await fetchPropertyListRequest({ params });

      const { entities } = normalize(propertyList, [propertySchema]);

      return entities;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchProperty = createAsyncThunk(
  'properties/fetchProperty',
  async (options, { rejectWithValue }) => {
    try {
      const { code = 0, params = {} } = options;

      const property = await fetchPropertyRequest({ code, params });

      const { entities } = normalize(property, propertySchema);

      return entities;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addProperty = createAsyncThunk(
  'properties/addProperty',
  async (options, { rejectWithValue }) => {
    try {
      const { data } = options;

      const property = await insertPropertyRequest({ data });
      const { entities } = normalize(property, propertySchema);

      return entities;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateProperty = createAsyncThunk(
  'properties/updateProperty',
  async (options, { rejectWithValue }) => {
    try {
      const { code, data } = options;

      const changes = await updatePropertyRequest({ code, data });

      return { changes, code };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Adapter
const propertiesAdapter = createEntityAdapter({
  selectId: property => property.code
});

// Slice
const propertySlice = createSlice({
  name: 'properties',
  initialState: propertiesAdapter.getInitialState(),
  extraReducers: builder => {
    builder
      .addCase(fetchPropertyList.fulfilled, (state, action) => {
        if (action.payload.Property) {
          propertiesAdapter.setAll(state, action.payload.Property);
        } else {
          propertiesAdapter.removeAll(state);
        }
      })
      .addCase(fetchProperty.fulfilled, (state, action) => {
        propertiesAdapter.setAll(state, action.payload.Property);
      })
      .addCase(addProperty.fulfilled, (state, action) => {
        propertiesAdapter.upsertMany(state, action.payload.Property);
      })
      .addCase(updateProperty.fulfilled, (state, action) => {
        propertiesAdapter.updateOne(state, {
          id: action.payload.code,
          changes: action.payload.changes
        });
      });
  }
});

// Selectors
export const {
  selectIds: selectPropertyCodeList,
  selectById: selectPropertyByCode
} = propertiesAdapter.getSelectors(state => state.properties);

// Reducer
export default propertySlice.reducer;
