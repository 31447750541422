import * as classNames from 'classnames';
import * as PropTypes from 'prop-types';
import React from 'react';

const Text = ({
  as: Component, className, color, bgColor, justify, align, smAlign, mdAlign, lgAlign, xlAlign,
  wrapping, capitalization, weight, italic, monospace, noDecoration, ...rest
}) => {
  const classes = classNames(
    className,
    color && `text-${color}`,
    bgColor && `bg-${bgColor}`,
    justify && 'text-justify',
    align && `text-${align}`,
    smAlign && `text-sm-${smAlign}`,
    mdAlign && `text-md-${mdAlign}`,
    lgAlign && `text-lg-${lgAlign}`,
    xlAlign && `text-xl-${xlAlign}`,
    wrapping && `text-${wrapping}`,
    capitalization && `text-${capitalization}`,
    weight && `font-weight-${weight}`,
    italic && 'font-italic',
    monospace && 'text-monospace',
    noDecoration && 'text-decoration-none',
  );

  return (
    <Component className={classes} {...rest} />
  );
};

Text.propTypes = {
  as: PropTypes.elementType,
  className: PropTypes.string,
  color: PropTypes.oneOf(['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark', 'body', 'muted', 'white', 'black-50', 'white-50', 'reset']),
  bgColor: PropTypes.oneOf(['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark', 'white', 'transparent']),
  justify: PropTypes.bool,
  align: PropTypes.oneOf(['left', 'center', 'right']),
  smAlign: PropTypes.oneOf(['left', 'center', 'right']),
  mdAlign: PropTypes.oneOf(['left', 'center', 'right']),
  lgAlign: PropTypes.oneOf(['left', 'center', 'right']),
  xlAlign: PropTypes.oneOf(['left', 'center', 'right']),
  wrapping: PropTypes.oneOf(['wrap', 'nowrap', 'truncate', 'break']),
  capitalization: PropTypes.oneOf(['lowercase', 'uppercase', 'capitalize']),
  weight: PropTypes.oneOf(['bold', 'bolder', 'normal', 'light', 'lighter']),
  italic: PropTypes.bool,
  monospace: PropTypes.bool,
  noDecoration: PropTypes.bool,
};

Text.defaultProps = {
  as: 'p',
  className: undefined,
  color: undefined,
  bgColor: undefined,
  justify: undefined,
  align: undefined,
  smAlign: undefined,
  mdAlign: undefined,
  lgAlign: undefined,
  xlAlign: undefined,
  wrapping: undefined,
  capitalization: undefined,
  weight: undefined,
  italic: undefined,
  monospace: undefined,
  noDecoration: undefined,
};

export default Text;
