import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { uploadPdfAttachment } from '../attachments/pdfsSlice';
import { uploadXlsxAttachment } from '../attachments/xlsxsSlice';
import { fetchStatementList } from './statementsOperations';

// Adapter
const statementsAdapter = createEntityAdapter({
  selectId: statement => statement.period,
  sortComparer: (a, b) => b.period - a.period
});

// Slice
const statementsSlice = createSlice({
  name: 'statements',
  initialState: statementsAdapter.getInitialState(),
  extraReducers: builder => {
    builder
      .addCase(fetchStatementList.fulfilled, (state, action) => {
        statementsAdapter.setAll(state, action.payload.Statement);
      })
      .addCase(uploadPdfAttachment.fulfilled, (state, action) => {
        statementsAdapter.updateOne(state, {
          id: action.payload.statement,
          changes: { pdf: action.payload.statement }
        });
      })
      .addCase(uploadXlsxAttachment.fulfilled, (state, action) => {
        statementsAdapter.updateOne(state, {
          id: action.payload.statement,
          changes: { xlsx: action.payload.statement }
        });
      });
  }
});

// Selectors
export const { selectIds: selectStatementIdList, selectById: selectStatementById } = statementsAdapter.getSelectors(state => state.statements);

// Reducer
export default statementsSlice.reducer;
