import * as PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AttachmentCell from './AttachmentCell';
import { deleteXlsxAttachment, downloadXlsxAttachment, selectXlsxByPeriod, uploadXlsxAttachment } from './xlsxsSlice';

const XlsxAttachmentCell = ({ accountId, ownerId, period, xlsxPeriod }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [progressValue, setProgressValue] = useState(0);
  const xlsx = useSelector(state => selectXlsxByPeriod(state, xlsxPeriod));
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoading && xlsxPeriod && progressValue === 100) {
      setIsLoading(false);
      setProgressValue(0);
    }
  }, [xlsxPeriod, isLoading, progressValue]);

  const handleProgress = progressEvent => {
    const value = (progressEvent.loaded / progressEvent.total) * 100;
    setProgressValue(value);
  };

  const handleError = error => {
    setError(error);
  };

  const handleUploadFile = useCallback(formData => {
    setIsLoading(true);
    dispatch(uploadXlsxAttachment({
      accountId,
      period,
      formData,
      handleProgress,
      handleError
    }));
  }, [dispatch, accountId, period]);

  const handleDownloadFile = useCallback(() => {
    setIsLoading(true);
    dispatch(downloadXlsxAttachment({
      accountId,
      period,
      fileName: xlsx.filename,
      handleProgress,
      handleError,
      params: {
        owner_id: ownerId
      }
    }));
  }, [dispatch, accountId, ownerId, period, xlsx]);

  const handleDeleteClick = useCallback(() => {
    dispatch(deleteXlsxAttachment({
      accountId,
      period,
      handleError
    }));
  }, [dispatch, accountId, period]);

  const handleErrorModalHide = useCallback(() => {
    setError(false);
  }, []);

  return (
    <AttachmentCell
      attachmentType="xlsx"
      isLoading={isLoading}
      progressValue={progressValue}
      onUpload={handleUploadFile}
      onDownloadClick={handleDownloadFile}
      onDeleteClick={handleDeleteClick}
      attachment={xlsx}
      onErrorModalHide={handleErrorModalHide}
      error={error}
    />
  );

};

XlsxAttachmentCell.propTypes = {
  ownerId: PropTypes.number.isRequired,
  accountId: PropTypes.number.isRequired,
  period: PropTypes.string.isRequired,
  xlsxPeriod: PropTypes.string
};

XlsxAttachmentCell.defaultProps = {
  xlsxPeriod: null
};

export default XlsxAttachmentCell;