import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Loader from '../../common/Loader';
import Text from '../../common/Text';
import useAsyncThunkFlagsSelector from '../../common/useAsyncThunkFlagsSelector';
import AccountListCard from '../accounts/AccountsListCard';
import { fetchAccountList } from '../accounts/accountsSlice';
import ErrorModal from '../errors/ErrorModal';
import useResetAsyncThunkErrorCallback from '../errors/useResetAsyncThunkErrorCallback';
import PropertyListCard from '../properties/ListCard';
import { fetchPropertyList } from '../properties/slice';
import { fetchOwner, selectOwnerById } from './ownersSlice';

const OwnersDetailPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const owner = useSelector(state => selectOwnerById(state, id));
  const { isLoading, error } = useAsyncThunkFlagsSelector(fetchOwner);
  const handleErrorModalHide = useResetAsyncThunkErrorCallback(fetchOwner);
  let title;

  useEffect(() => {
    dispatch(fetchOwner({ id }));

    dispatch(fetchAccountList({
      query: {
        owner_id: id,
        inclusions: ['owner', 'balance'],
      }
    }));

    dispatch(fetchPropertyList({
      params: {
        owner_code: id
      }
    }));
  }, [id, dispatch]);

  if (isLoading || !owner) {
    title = <Loader height={2.5} length={50} />;
  } else {
    title = <Text as="h1">{owner.name}</Text>;
  }

  return (
    <>
      {title}

      <hr />

      <AccountListCard className="mb-3" />

      <PropertyListCard className="mb-3" />

      <ErrorModal onHide={handleErrorModalHide} error={error} />
    </>
  );
};

export default OwnersDetailPage;