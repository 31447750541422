import * as PropTypes from 'prop-types';
import React from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import { useSelector } from 'react-redux';
import Loader from '../../common/Loader';
import Text from '../../common/Text';
import useAsyncThunkFlagsSelector from '../../common/useAsyncThunkFlagsSelector';
import ErrorModal from '../errors/ErrorModal';
import useResetAsyncThunkErrorCallback from '../errors/useResetAsyncThunkErrorCallback';
import AccountsTableRow from './AccountsTableRow';
import { fetchAccountList, selectAccountIdList } from './accountsSlice';

const LoaderRow = () => (
  <tr>
    <td>
      <Loader length={50} />
    </td>
    <td>
      <Loader length={75} />
    </td>
    <td className="d-flex justify-content-end">
      <Loader length={50} />
    </td>
  </tr>
);

const AccountListCard = ({ className }) => {
  const accountIdList = useSelector(selectAccountIdList);
  const { isLoading, error } = useAsyncThunkFlagsSelector(fetchAccountList);
  const handleErrorModalHide = useResetAsyncThunkErrorCallback(fetchAccountList);

  return (
    <Card className={className}>
      <Card.Header>
        <Row>
          <Col md>
            <Text as="h2" className="mb-0">口座一覧</Text>
          </Col>
        </Row>
      </Card.Header>

      <Card.Body>
        <Table responsive hover size="sm">
          <thead>
            <tr>
              <th>口座番号</th>
              <th>オーナー名</th>
              <th className="text-right">残高</th>
            </tr>
          </thead>

          <tbody>
            {isLoading ?
              [1, 2, 3, 4, 5].map(value =>
                <LoaderRow key={value} />
              ) :
              accountIdList.map(accountId =>
                <AccountsTableRow id={accountId} key={accountId} />
              )
            }
          </tbody>
        </Table>

        <ErrorModal onHide={handleErrorModalHide} error={error} />
      </Card.Body>
    </Card>
  );
};

AccountListCard.propTypes = {
  className: PropTypes.string
};

AccountListCard.defaultProps = {
  className: '',
};

export default AccountListCard;
