import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons/faTrashAlt';
import { faUpload } from '@fortawesome/free-solid-svg-icons/faUpload';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as PropTypes from 'prop-types';
import React, { createRef, useCallback, useState } from 'react';
import Button from 'react-bootstrap/Button';
import ProgressBar from 'react-bootstrap/ProgressBar';
import DeleteModal from '../../common/DeleteModal';
import ErrorModal from '../errors/ErrorModal';

const AttachmentCell = ({
  attachmentType,
  isLoading,
  progressValue,
  onUpload,
  onDownloadClick,
  onDeleteClick,
  attachment,
  onErrorModalHide,
  error
}) => {
  const fileInputRef = createRef();
  const [isDeleting, setIsDeleting] = useState(false);
  const [fileSizeError, setFileSizeError] = useState(null);

  const handleFileChanged = e => {
    const field = e.currentTarget;
    const file = field.files[0];


    if (checkFileSize(file)) {
      const formData = new FormData();
      formData.append(field.name, file);

      onUpload(formData);
    }
  };

  const handleDeleteClick = useCallback(() => setIsDeleting(true), []);

  const handleConfirmDeleteClick = useCallback(() => {
    onDeleteClick();
    setIsDeleting(false);
  }, [onDeleteClick])

  const handleCancelClick = useCallback(() => setIsDeleting(false), []);

  const handleErrorModalHide = useCallback(() => {
    setFileSizeError(null);
    onErrorModalHide();
  }, [onErrorModalHide]);

  const checkFileSize = file => {
    // Convert file size to MB
    const sizeInMB = file.size / 1024 / 1024;

    // If file size is greater than 10MB, set file size error
    if (sizeInMB > 10) {
      setFileSizeError({
        name: 'SimpleError',
        title: 'File size error',
        content: 'File size is greater than 10MB'
      });

      return false;
    }

    return true;
  };

  if (isLoading) {
    return (
      <td>
        <ProgressBar animated variant="info" now={progressValue} />
      </td>
    );
  } else if (attachment) {
    return (
      <td>
        <Button variant="secondary" className="mr-1" onClick={onDownloadClick}>
          <FontAwesomeIcon icon={faDownload} />
          ダウンロード
        </Button>
        <Button variant="danger" onClick={handleDeleteClick}>
          <FontAwesomeIcon icon={faTrashAlt} />
        </Button>

        <DeleteModal
          show={isDeleting}
          onDeleteClick={handleConfirmDeleteClick}
          onCancelClick={handleCancelClick}
        />

        <ErrorModal onHide={handleErrorModalHide} error={error || fileSizeError} />
      </td>
    );
  }

  return (
    <td>
      <Button variant="secondary" onClick={() => fileInputRef.current.click()}>
        <FontAwesomeIcon icon={faUpload} />
        アップロード
      </Button>
      <input
        ref={fileInputRef}
        type="file"
        accept={`.${attachmentType}`}
        hidden
        name="attachment"
        onChange={handleFileChanged}
      />

      <ErrorModal onHide={handleErrorModalHide} error={error || fileSizeError} />
    </td>
  );
};

AttachmentCell.propTypes = {
  attachmentType: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  progressValue: PropTypes.number.isRequired,
  onUpload: PropTypes.func.isRequired,
  onDownloadClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  attachment: PropTypes.any,
  onErrorModalHide: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool
  ])
};

AttachmentCell.defaultProps = {
  attachment: null,
  error: null
};

export default AttachmentCell;
