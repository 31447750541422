import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { normalize } from 'normalizr';
import { ownerSchema } from '../../app/schema';
import { fetchAccountList } from '../accounts/accountsSlice';
import { fetchOwnerListRequest, fetchOwnerRequest } from './ownersApi';

// Operations
export const fetchOwnerList = createAsyncThunk(
  'owners/fetchOwnerList',
  async (options = {}, { rejectWithValue }) => {
    try {
      const { query = {} } = options;

      const { data: ownerList } = await fetchOwnerListRequest({ query });

      const { entities } = normalize(ownerList, [ownerSchema]);

      return entities;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchOwner = createAsyncThunk(
  'owners/fetchOwner',
  async (options, { rejectWithValue }) => {
    try {
      const { id = 0, query = {} } = options;

      const { data: owner } = await fetchOwnerRequest({ id, query });

      const { entities } = normalize(owner, ownerSchema);

      return entities;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Adapter
const ownersAdapter = createEntityAdapter({
  selectId: owner => owner.id
});

// Slice
const ownerSlice = createSlice({
  name: 'owners',
  initialState: ownersAdapter.getInitialState(),
  extraReducers: builder => {
    builder
      .addCase(fetchOwnerList.fulfilled, (state, action) => {
        if (action.payload.Owner) {
          ownersAdapter.setAll(state, action.payload.Owner);
        } else {
          ownersAdapter.removeAll(state);
        }
      })
      .addCase(fetchOwner.fulfilled, (state, action) => {
        ownersAdapter.setAll(state, action.payload.Owner);
      })
      .addCase(fetchAccountList.fulfilled, (state, action) => {
        if (action.payload.Owner) {
          ownersAdapter.upsertMany(state, action.payload.Owner);
        }
      });
  }
});

// Selectors
export const { selectIds: selectOwnerIdList, selectById: selectOwnerById } = ownersAdapter.getSelectors(state => state.owners);

// Reducer
export default ownerSlice.reducer;
