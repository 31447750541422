import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { normalize } from 'normalizr';
import { accountSchema } from '../../app/schema';
import { fetchAccountListRequest, fetchAccountRequest } from './accountsApi';

// Adapter
const accountsAdapter = createEntityAdapter({
  selectId: account => account.id,
  sortComparer: (a, b) => b.id - a.id
});

// Operations
export const fetchAccountList = createAsyncThunk(
  'accounts/fetchAccountList',
  async (options, { rejectWithValue }) => {
    try {
      const { query = {} } = options;

      const { data: accountList } = await fetchAccountListRequest({ query });

      const { entities } = normalize(accountList, [accountSchema]);

      return entities;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchAccount = createAsyncThunk(
  'accounts/fetchAccount',
  async (options, { rejectWithValue }) => {
    try {
      const { id = 0, query = {} } = options;

      const { data: account } = await fetchAccountRequest({ id, query });

      const { entities } = normalize(account, accountSchema);

      return entities;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Slice
const accountsSlice = createSlice({
  name: 'accounts',
  initialState: accountsAdapter.getInitialState(),
  extraReducers: builder => {
    builder
      .addCase(fetchAccountList.fulfilled, (state, action) => {
        if (action.payload.Account) {
          accountsAdapter.setAll(state, action.payload.Account);
        } else {
          accountsAdapter.removeAll(state);
        }
      })
      .addCase(fetchAccount.fulfilled, (state, action) => {
        accountsAdapter.setAll(state, action.payload.Account);
      });
  }
});

// Selectors
export const { selectIds: selectAccountIdList, selectById: selectAccountById } = accountsAdapter.getSelectors(state => state.accounts);

// Reducer
export default accountsSlice.reducer;
