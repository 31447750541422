import { useFormik } from 'formik';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import SpinnerButton from '../../common/SpinnerButton';
import useAsyncThunkFlagsSelector from '../../common/useAsyncThunkFlagsSelector';
import ErrorText from '../errors/ErrorText';
import useResetAsyncThunkErrorCallback from '../errors/useResetAsyncThunkErrorCallback';
import { addRoom } from './slice';

const AddModal = () => {
  const { code: propertyCode } = useParams();
  const dispatch = useDispatch();
  const [isShown, setIsShown] = useState(false);
  const { isLoading, error } = useAsyncThunkFlagsSelector(addRoom);
  const resetAddRoomError = useResetAsyncThunkErrorCallback(addRoom);

  const handleAddClick = () => setIsShown(true);

  const handleCancelClick = () => {
    resetAddRoomError();
    setIsShown(false);
    formik.resetForm();
  };

  const formik = useFormik({
    initialValues: {
      property_code: propertyCode || '',
      owner_code: '',
      room_number: ''
    },
    onSubmit: (values, { resetForm }) => {
      const data = {
        ...values,
        owner_code: Number(values.owner_code)
      };
      dispatch(addRoom({ data }));
      setIsShown(false);
      resetForm();
    }
  });

  return (
    <>
      {!isShown && (
        <Button onClick={handleAddClick} variant="dark">追加</Button>
      )}

      <Modal show={isShown} onHide={handleCancelClick} backdrop="static" centered>
        <Form noValidate onSubmit={formik.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>部屋追加</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form.Group>
              <Form.Label>物件コード</Form.Label>
              <Form.Control
                type="text"
                placeholder="0000000000"
                name="property_code"
                value={formik.values.property_code}
                onChange={formik.handleChange}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>オーナーコード</Form.Label>
              <Form.Control
                type="text"
                placeholder="0000"
                name="owner_code"
                value={formik.values.owner_code}
                onChange={formik.handleChange}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>部屋番号</Form.Label>
              <Form.Control
                type="text"
                placeholder="0000000000"
                name="room_number"
                value={formik.values.room_number}
                onChange={formik.handleChange}
              />
            </Form.Group>
          </Modal.Body>

          <Modal.Footer>
            <div className="w-100 d-flex justify-content-end">
              <ErrorText className="mb-0" error={error} />
            </div>

            <Button variant="secondary" onClick={handleCancelClick}>キャンセル</Button>

            <SpinnerButton
              type="submit"
              variant="primary"
              loading={formik.isSubmitting || isLoading}
            >
              保存
            </SpinnerButton>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default AddModal;
