import { useFormik } from 'formik';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useDispatch } from 'react-redux';
import SpinnerButton from '../../common/SpinnerButton';
import useAsyncThunkFlagsSelector from '../../common/useAsyncThunkFlagsSelector';
import { fetchAccountList } from './accountsSlice';

const AccountsSearchForm = () => {
  const dispatch = useDispatch();
  const { isLoading } = useAsyncThunkFlagsSelector(fetchAccountList);

  const handleSearch = query => {
    dispatch(fetchAccountList({
      query: {
        ...query,
        inclusions: ['owner', 'balance'],
      }
    }));
  };

  const formik = useFormik({
    initialValues: {
      account_number: '',
      owner_name: ''
    },
    onSubmit: handleSearch,
  });

  return (
    <Form autoComplete="off" onSubmit={formik.handleSubmit}>
      <Row>
        <Col>
          <Form.Group as={Row} controlId="field-account-number">
            <Form.Label column sm="4" className="text-right">口座番号</Form.Label>
            <Col>
              <Form.Control
                name="account_number"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.account_number}
              />
            </Col>
          </Form.Group>
        </Col>

        <Col>
          <Form.Group as={Row} controlId="field-owner-name">
            <Form.Label column sm="4" className="text-right">オーナー名</Form.Label>
            <Col>
              <Form.Control
                name="owner_name"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.owner_name}
              />
            </Col>
          </Form.Group>
        </Col>
      </Row>

      <Row className="justify-content-end">
        <Col xs="auto">
          <SpinnerButton
            loading={isLoading}
            type="submit"
            variant="primary"
          >
            検索
          </SpinnerButton>
        </Col>
      </Row>
    </Form>
  );
};

export default AccountsSearchForm;
