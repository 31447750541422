import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Text from '../../common/Text';
import AccountListCard from './AccountsListCard';
import AccountsSearchForm from './AccountsSearchForm';
import { fetchAccountList } from './accountsSlice';

const AccountsSearchPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAccountList({
      query: {
        inclusions: ['owner', 'balance'],
      }
    }));
  }, [dispatch])

  return (
    <>
      <Text as="h1">口座</Text>

      <hr />

      <AccountsSearchForm />

      <AccountListCard className="my-3" />
    </>
  );
};

export default AccountsSearchPage;
