import { useFormik } from 'formik';
import * as PropTypes from 'prop-types';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import Loader from '../../common/Loader';
import SpinnerButton from '../../common/SpinnerButton';
import Text from '../../common/Text';
import useAsyncThunkFlagsSelector from '../../common/useAsyncThunkFlagsSelector';
import objectDifference from '../../utils/object-difference';
import ErrorText from '../errors/ErrorText';
import useResetAsyncThunkErrorCallback from '../errors/useResetAsyncThunkErrorCallback';
import { fetchProperty, selectPropertyByCode, updateProperty } from './slice';

const DetailsCard = ({ className }) => {
  const { code } = useParams();
  const dispatch = useDispatch();
  const property = useSelector(state => selectPropertyByCode(state, code));
  const [isEditing, setIsEditing] = useState(false);
  const { isLoading } = useAsyncThunkFlagsSelector(fetchProperty);
  const { isLoading: isUpdating, error } = useAsyncThunkFlagsSelector(updateProperty);
  const resetUpdatePropertyError = useResetAsyncThunkErrorCallback(updateProperty);

  const handleCancelClick = () => {
    resetUpdatePropertyError();
    setIsEditing(false);
    formik.resetForm();
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: property,
    onSubmit: (values, { resetForm }) => {
      const data = objectDifference(property, {
        ...values,
        owner_code: Number(values.owner_code)
      });

      if (data) {
        dispatch(updateProperty({
          code,
          data
        }));
      }

      setIsEditing(false);
      resetForm();
    }
  });

  return (
    <Card className={className} >
      <Card.Header>
        <Row>
          <Col md>
            <Text as="h2" className="mb-0">物件情報</Text>
          </Col>

          {!isLoading &&
            <Col md>
              <Row className="justify-content-end">
                <Col md="auto">
                  {!isEditing
                    ? <Button onClick={() => setIsEditing(true)} variant="dark">変更</Button>
                    : <Button onClick={handleCancelClick} variant="secondary">キャンセル</Button>
                  }
                </Col>
              </Row>
            </Col>
          }
        </Row>
      </Card.Header>

      <Card.Body>
        {isLoading || !property
          ? <Row>
            <Col>
              <Loader length={50} />
            </Col>
            <Col>
              <Loader length={50} />
            </Col>
          </Row>
          : <>
            {!isEditing ? (
              /* Read View */
              <>
                <Row>
                  <Col>
                    <Text>コード: {property.code}</Text>
                  </Col>
                  <Col>
                    <Text>オーナーコード: <Link to={`/owners/${property.owner_code}`}>{property.owner_code}</Link></Text>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Text>口座コード: <Link to={`/accounts/${property.account_code}`}>{property.account_code}</Link></Text>
                  </Col>
                  <Col>
                    <Text>物件名: {property.name}</Text>
                  </Col>
                </Row>

                <Text>住所日本語: {property.address_ja}</Text>
                <Text>住所英語: {property.address_en}</Text>
              </>
            ) : (
              /* Edit View */
              <Form noValidate onSubmit={formik.handleSubmit}>
                <Form.Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>コード</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="0000000000"
                        name="code"
                        value={formik.values.code}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                  </Col>

                  <Col>
                    <Form.Group>
                      <Form.Label>オーナーコード</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="0000"
                        name="owner_code"
                        value={formik.values.owner_code}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Form.Row>

                <Form.Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>口座コード</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="0000000"
                        name="account_code"
                        value={formik.values.account_code}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                  </Col>

                  <Col>
                    <Form.Group>
                      <Form.Label>物件名</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="The Mansion"
                        name="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Form.Row>


                <Form.Group>
                  <Form.Label>住所日本語</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="住所記入"
                    name="address_ja"
                    value={formik.values.address_ja}
                    onChange={formik.handleChange}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>住所英語</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="住所記入"
                    name="address_en"
                    value={formik.values.address_en}
                    onChange={formik.handleChange}
                  />
                </Form.Group>

                <div className="d-flex flex-wrap justify-content-end">
                  <div className="w-100 d-flex justify-content-end m-1">
                    <ErrorText className="mb-0" error={error} />
                  </div>

                  <Button className="m-1" variant="secondary" onClick={handleCancelClick}>キャンセル</Button>

                  <SpinnerButton
                    type="submit"
                    className="m-1"
                    variant="primary"
                    loading={formik.isSubmitting || isUpdating}
                  >
                    保存
                  </SpinnerButton>
                </div>
              </Form>
            )}
          </>
        }
      </Card.Body>
    </Card >
  );
};

DetailsCard.propTypes = {
  className: PropTypes.string
};

DetailsCard.defaultProps = {
  className: '',
};

export default DetailsCard;
