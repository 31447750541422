import config from '../../app/config';
import fileSaver from '../../utils/file-saver';
import { deleteFile, downloadFile, uploadFile } from '../../utils/request';

export const uploadAttachmentRequest = (accountId, period, type, formData, handleProgress) => uploadFile({
  url: `${config.api.url}/virtual-accounts/${accountId}/statements/${period}/${type}`,
  data: formData,
  onUploadProgress: handleProgress,
});

export const downloadAttachmentRequest = async (accountId, period, type, fileName, handleProgress = undefined, params = undefined) => {
  const file = await downloadFile({
    url: `${config.api.url}/virtual-accounts/${accountId}/statements/${period}/${type}`,
    onDownloadProgress: handleProgress,
    params
  });

  return fileSaver(file, fileName);
};

export const generateAttachmentRequest = async (accountId, period, type, fileName, handleProgress = undefined, params = undefined) => {
  let accept;
  switch (type) {
    case 'xlsx':
      accept = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      break;
    default:
      throw new Error(`Unknown attachment type: ${type}`);
  }

  const file = await downloadFile({
    url: `${config.api.url}/virtual-accounts/${accountId}/statements/${period}`,
    onDownloadProgress: handleProgress,
    params,
    headers: {
      'Accept': accept
    }
  });

  return fileSaver(file, fileName);
}

export const deleteAttachmentRequest = (accountId, period, type) => deleteFile({
  url: `${config.api.url}/virtual-accounts/${accountId}/statements/${period}/${type}`,
});
