import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { normalize } from 'normalizr';
import { roomSchema } from '../../app/schema';
import { fetchRoomListRequest, insertRoomRequest } from './api';

// Operations
export const fetchRoomList = createAsyncThunk(
    'rooms/fetchRoomList',
    async (options = {}, { rejectWithValue }) => {
        try {
            const { params = {} } = options;

            const roomList = await fetchRoomListRequest({ params });

            const { entities } = normalize(roomList, [roomSchema]);

            return entities;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const addRoom = createAsyncThunk(
    'rooms/addRoom',
    async (options, { rejectWithValue }) => {
        try {
            const { data } = options;

            const room = await insertRoomRequest({ data });

            const { entities } = normalize(room, roomSchema);

            return entities;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

// Adapter
const roomsAdapter = createEntityAdapter({
    selectId: room => `${room.owner_code}-${room.property_code}-${room.room_number}`
});

// Slice
const roomSlice = createSlice({
    name: 'rooms',
    initialState: roomsAdapter.getInitialState(),
    extraReducers: builder => {
        builder
            .addCase(fetchRoomList.fulfilled, (state, action) => {
                if (action.payload.Room) {
                    roomsAdapter.setAll(state, action.payload.Room);
                } else {
                    roomsAdapter.removeAll(state);
                }
            })
            .addCase(addRoom.fulfilled, (state, action) => {
                roomsAdapter.upsertMany(state, action.payload.Room);
            });
    }
});

// Selectors
export const {
    selectIds: selectRoomCodeList,
    selectById: selectRoomByCode
} = roomsAdapter.getSelectors(state => state.rooms);

// Reducer
export default roomSlice.reducer;
