import config from '../../app/config';
import authRequest from '../../utils/request';

export const fetchOwnerListRequest = ({ query }) => authRequest({
  url: `${config.api.url}/owners`,
  params: query,
});

export const fetchOwnerRequest = ({ id, query }) => authRequest({
  url: `${config.api.url}/owners/${id}`,
  params: query,
});

