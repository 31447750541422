import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as classNames from 'classnames';
import * as PropTypes from 'prop-types';
import React from 'react';
import Alert from 'react-bootstrap/Alert';
import Modal from 'react-bootstrap/Modal';
import Text from '../../common/Text';
import useProcessedError from './useProcessedError';

const ErrorModal = ({ onHide, error }) => {
  const processedError = useProcessedError(error);

  if (!processedError) {
    return null;
  }

  return (
    <Modal show={!!processedError} onHide={onHide} centered>
      <Modal.Body className="p-0">
        <Alert className="mb-0" variant="danger" onClose={onHide} dismissible>
          <Alert.Heading as="h2">
            <FontAwesomeIcon icon={faExclamationCircle} />
            {` ${processedError.title}`}
          </Alert.Heading>

          {Array.isArray(processedError.content) ?
            processedError.content.map((content, index) => (
              <Text className={classNames(index + 1 !== processedError.content.length && "mb-0")} key={index}>
                {content}
              </Text>
            )) : (
              <Text>{processedError.content}</Text>
            )}
        </Alert>
      </Modal.Body>
    </Modal>
  );
};

ErrorModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool
  ])
};

ErrorModal.defaultProps = {
  error: null,
  as: 'p',
  className: undefined,
  align: undefined
};

export default ErrorModal;

