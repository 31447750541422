const config = {
  auth: {
      url: process.env.AUTH_URL || '',
      client_id: process.env.AUTH_CLIENT_ID || '',
      client_secret: process.env.AUTH_CLIENT_SECRET || ''
  },
  api: {
    url: process.env.API_URL || '',
  },
  freee: {
    clientId: process.env.FREEE_CLIENT_ID || '',
    redirectUri: process.env.FREEE_REDIRECT_URI || '',
  }
};

export default config;
