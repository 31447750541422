import { useEffect } from 'react';

/**
 * Hook to set the document title.
 * @param {string} extension The document title extension.
 */
const useDocumentTitle = (extension = '') => {
    const title = 'Owners Admin';
    useEffect(() => {
        if (extension) {
            document.title = title + ' | ' + extension
        } else {
            document.title = title;
        }
    }, [extension]);
};

export default useDocumentTitle;