import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Text from '../../common/Text';
import ListCard from './ListCard';
import SearchForm from './SearchForm';
import { fetchPropertyList } from './slice';

const IndexPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPropertyList());
  }, [dispatch]);

  return (
    <>
      <Text as="h1">物件</Text>

      <hr />

      <SearchForm />

      <ListCard className="my-3" />
    </>
  );
};

export default IndexPage;
