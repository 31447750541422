import * as PropTypes from 'prop-types';
import React from 'react';
import Text from '../../common/Text';
import useProcessedError from './useProcessedError';

const ErrorText = ({ error, ...props }) => {
  const processedError = useProcessedError(error);

  if (!processedError) {
    return null;
  }

  if (Array.isArray(processedError.content)) {
    return (
      <Text color="danger" {...props}>
        {`${processedError.title}: \n`}
        {processedError.content.map(content => `${content}\n`)}
      </Text>
    );
  }

  return (
    <Text color="danger" {...props}>
      {`${processedError.title}: ${processedError.content}`}
    </Text>
  );
};

ErrorText.propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool
  ]),
  as: PropTypes.elementType,
  className: PropTypes.string,
  align: PropTypes.oneOf(['left', 'center', 'right'])
};

ErrorText.defaultProps = {
  error: null,
  as: 'p',
  className: undefined,
  align: undefined
};

export default ErrorText;
