import React, { useCallback, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import config from '../../app/config';
import logo from '../../images/logo_hj.svg';
import authRequest from '../../utils/request';
import LogoutModal from '../authentication/LogoutModal';
import { selectSessionUser } from '../session/sessionSlice';

const Header = () => {
  const [isLogoutShown, setIsLogoutShown] = useState(false);
  const handleLogoutClick = useCallback(() => setIsLogoutShown(true), []);
  const handleModalHide = useCallback(() => setIsLogoutShown(false), []);
  const handleSetupFreeeClick = async () => {
    const { state } = await authRequest({ url: `${config.api.url}/freee/state` });

    // Redirect to freee login
    window.location.href = `https://accounts.secure.freee.co.jp/public_api/authorize?client_id=${config.freee.clientId}&redirect_uri=${config.freee.redirectUri}&prompt=select_company&response_type=code&state=${state}`;
  };
  const User = useSelector(selectSessionUser);

  return (
    <>
      <Navbar
        fixed="top"
        expand="lg"
        variant="dark"
        bg="dark"
      >
        <Container>
          <Navbar.Brand as={Link} to="/">
            <img src={logo} alt="HJ" width="30" height="30" />
            {' オーナーの管理ページ'}
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="navbar" />

          <Navbar.Collapse id="navbar">
            <Nav className="ml-auto">
              {
                User.permissions.includes('oauth_admin') &&
                <Nav.Item>
                  <Nav.Link onClick={handleSetupFreeeClick}>Connect Freee</Nav.Link>
                </Nav.Item>
              }
              <Nav.Item>
                <Nav.Link as={Link} to="/owners">オーナー</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={Link} to="/accounts">口座</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={Link} to="/properties">物件</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link onClick={handleLogoutClick}>ログアウト</Nav.Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <LogoutModal isShown={isLogoutShown} onHide={handleModalHide} />
    </>
  );
};

export default Header;
