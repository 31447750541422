import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Text from '../../common/Text';
import OwnersListCard from './OwnersListCard';
import { fetchOwnerList } from './ownersSlice';
import OwnersSearchForm from './OwnersSearchForm';

const OwnersSearchPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchOwnerList());
  }, [dispatch]);

  return (
    <>
      <Text as="h1">オーナー</Text>

      <hr />

      <OwnersSearchForm />

      <OwnersListCard className="my-3" />
    </>
  );
};

export default OwnersSearchPage;
