import config from '../../app/config';
import authRequest from '../../utils/request';

export const fetchAccountListRequest = ({ query }) => authRequest({
  url: `${config.api.url}/virtual-accounts`,
  params: query,
});

export const fetchAccountRequest = ({ id, query }) => authRequest({
  url: `${config.api.url}/virtual-accounts/${id}`,
  params: query,
});
