import config from '../../app/config';
import authRequest from '../../utils/request';

export const fetchPropertyListRequest = ({ params }) => authRequest({
  url: `${config.api.url}/properties`,
  params
});

export const insertPropertyRequest = ({ data }) => authRequest({
  url: `${config.api.url}/properties`,
  method: 'post',
  data,
});

export const fetchPropertyRequest = ({ code, params }) => authRequest({
  url: `${config.api.url}/properties/${code}`,
  params
});

export const updatePropertyRequest = ({ code, data }) => authRequest({
  url: `${config.api.url}/properties/${code}`,
  method: 'patch',
  data,
});
