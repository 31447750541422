import * as PropTypes from 'prop-types';
import React from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import { useSelector } from 'react-redux';
import Loader from '../../common/Loader';
import Text from '../../common/Text';
import useAsyncThunkFlagsSelector from '../../common/useAsyncThunkFlagsSelector';
import ErrorModal from '../errors/ErrorModal';
import useResetAsyncThunkErrorCallback from '../errors/useResetAsyncThunkErrorCallback';
import { selectSessionUser } from '../session/sessionSlice';
import { fetchPropertyList, selectPropertyCodeList } from './slice';
import TableRow from './TableRow';
import AddModal from './AddModal';

const LoaderRow = () => (
  <tr>
    <td>
      <Loader length={50} />
    </td>
    <td>
      <Loader length={50} />
    </td>
    <td>
      <Loader length={75} />
    </td>
  </tr>
);

const ListCard = ({ className }) => {
  const propertyCodeList = useSelector(selectPropertyCodeList);
  const { isLoading, error } = useAsyncThunkFlagsSelector(fetchPropertyList);
  const handleErrorModalHide = useResetAsyncThunkErrorCallback(fetchPropertyList);
  const User = useSelector(selectSessionUser);

  return (
    <Card className={className}>
      <Card.Header>
        <Row>
          <Col md>
            <Text as="h2" className="mb-0">物件一覧</Text>
          </Col>

          {
            User.permissions.includes('oauth_admin') &&
            <Col md>
              <Row className="justify-content-end">
                <Col md="auto">
                  <AddModal />
                </Col>
              </Row>
            </Col>
          }
        </Row>
      </Card.Header>


      <Card.Body>
        <Table responsive hover size="sm">
          <thead>
            <tr>
              <th>コード</th>
              <th>オーナーコード</th>
              <th>物件名</th>
            </tr>
          </thead>

          <tbody>
            {isLoading ?
              [1, 2, 3, 4, 5].map(value =>
                <LoaderRow key={value} />
              ) :
              propertyCodeList.map(propertyCode =>
                <TableRow code={propertyCode} key={propertyCode} />
              )
            }
          </tbody>
        </Table>

        <ErrorModal onHide={handleErrorModalHide} error={error} />
      </Card.Body>
    </Card>
  );
};

ListCard.propTypes = {
  className: PropTypes.string
};

ListCard.defaultProps = {
  className: '',
};

export default ListCard;
