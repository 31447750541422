import * as PropTypes from 'prop-types';
import React from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import Loader from '../../common/Loader';
import Text from '../../common/Text';
import useAsyncThunkFlagsSelector from '../../common/useAsyncThunkFlagsSelector';
import { fetchAccount, selectAccountById } from './accountsSlice';

const AccountsDetailCard = ({ className }) => {
  const { id } = useParams();
  const account = useSelector(state => selectAccountById(state, id));
  const { isLoading } = useAsyncThunkFlagsSelector(fetchAccount);

  return (
    <Card className={className} >
      <Card.Header as="h2">口座情報</Card.Header>

      <Card.Body>
        {isLoading || !account
          ? <Row>
            <Col>
              <Loader length={50} />
            </Col>
            <Col>
              <Loader length={50} />
            </Col>
          </Row>
          : <>
            <Row>
              <Col>
                <Text>ID: {account.id}</Text>
              </Col>
              <Col>
                <Text>オーナーID: <Link to={`/owners/${account.owner_id}`}>{account.owner_id}</Link></Text>
              </Col>
            </Row>

            <Row>
              <Col>
                <Text>銀行コード: 0009</Text>
              </Col>
              <Col>
                <Text>支店コード: 931</Text>
              </Col>
            </Row>

            <Row>
              <Col>
                <Text>口座種別: 1</Text>
              </Col>
              <Col>
                <Text>口座番号: {account.deposit_account_number}</Text>
              </Col>
            </Row>
          </>
        }
      </Card.Body>
    </Card >
  );
};

AccountsDetailCard.propTypes = {
  className: PropTypes.string
};

AccountsDetailCard.defaultProps = {
  className: '',
};

export default AccountsDetailCard;
