import axios from 'axios';
import * as tokenUtils from './token';

const isTokenExpired = (token) => {
  const tokenExpiresAt = token.expires_in + token.timestamp;

  // Return whether token is expired (with 10 second buffer to account for system time drift)
  return (tokenExpiresAt - 10 <= Math.floor(Date.now() / 1000));
};

const getToken = async () => {
  const token = tokenUtils.getToken();

  // If token exists
  if (token) {
    // If token is expired
    if (isTokenExpired(token)) {
      // Refresh token
      await tokenUtils.refreshToken();

      // Return new token
      return tokenUtils.getToken();
    } else {
      // Else; return token
      return token;
    }
  } else {
    // No token
    return Promise.reject(new Error('No token'));
  }
};

const authRequest = async (configuration) => {
  const token = await getToken();

  try {
    const { data } = await axios({
      ...configuration,
      headers: {
        ...configuration.headers,
        Authorization: `${token.token_type} ${token.access_token}`,
      },
    });

    // Return data
    return data;
  } catch (error) {
    if (error.response) {
      const errData = error.response.data;

      // Return rejected promise
      return Promise.reject(errData);
    } else if (error.request) {
      // Log error message
      console.error(error.message);

      // return rejected promise
      return Promise.reject(new Error('Unable to request'));
    } else {
      return Promise.reject(new Error(error));
    }
  }
};

export const uploadFile = (configuration) => authRequest({
  ...configuration,
  method: 'PUT',
});

export const downloadFile = (configuration) => authRequest({
  ...configuration,
  responseType: 'blob',
});

export const deleteFile = (configuration) => authRequest({
  ...configuration,
  method: 'DELETE',
});

export default authRequest;
