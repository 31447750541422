import classnames from 'classnames';
import * as PropTypes from 'prop-types';
import React from 'react';

const Loader = ({ length, height, className }) => {
  const classes = classnames(
    className,
    'loader',
  );

  return (
    <div className={classes} style={{ height: `${height}rem`, width: `${length}%` }} />
  );
};

Loader.propTypes = {
  height: PropTypes.number,
  length: PropTypes.number,
  className: PropTypes.string,
};

Loader.defaultProps = {
  height: 1,
  length: 100,
  className: undefined,
};

export default Loader;
