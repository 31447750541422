import * as PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectPropertyByCode } from './slice';

const TableRow = ({ code }) => {
    const property = useSelector(state => selectPropertyByCode(state, code));

    return (
        <tr>
            <td>
                <Link to={`/properties/${property.code}`}>{property.code}</Link>
            </td>
            <td>
                <Link to={`/owners/${property.owner_code}`}>{property.owner_code}</Link>
            </td>
            <td>{property.name}</td>
        </tr>
    );
};

TableRow.propTypes = {
    code: PropTypes.string.isRequired
};

export default TableRow;
