import numeral from 'numeral';
import * as PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import numberColor from '../../utils/number-color';
import PdfAttachmentCell from '../attachments/PdfAttachmentCell';
import XlsxAttachmentCell from '../attachments/XlsxAttachmentCell';
import XlsxWorkbookCell from '../attachments/XlsxWorkbookCell';
import { selectStatementById } from './statementsSlice';

const StatementsTableRow = ({ periodId }) => {
  const statement = useSelector(state => selectStatementById(state, periodId));

  return (
    <tr>
      <td className="align-middle">
        {`${statement.year}/${statement.month}`}
      </td>
      <td className={`align-middle text-${numberColor(statement.balance)}`}>
        &yen; {numeral(statement.balance).format('0,0')}
      </td>
      <PdfAttachmentCell accountId={statement.account_id} period={statement.period} ownerId={statement.owner_id} pdfPeriod={statement.pdf} />
      <XlsxAttachmentCell accountId={statement.account_id} period={statement.period} ownerId={statement.owner_id} xlsxPeriod={statement.xlsx} />
      <XlsxWorkbookCell accountId={statement.account_id} period={statement.period} ownerId={statement.owner_id} />
    </tr>
  );
};
StatementsTableRow.propTypes = {
  periodId: PropTypes.string.isRequired
};

export default StatementsTableRow;
