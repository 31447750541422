import { createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { normalize } from 'normalizr';
import { deleteAttachmentRequest, downloadAttachmentRequest, generateAttachmentRequest, uploadAttachmentRequest } from './attachmentsApi';

// Operations
export const createUploadAttachmentThunk = (typePrefix, type, schema) => createAsyncThunk(
  typePrefix,
  async options => {
    try {
      const { accountId, period, formData, handleProgress } = options;

      const result = await uploadAttachmentRequest(accountId, period, type, formData, handleProgress);

      const { entities } = normalize(result, schema);

      return { ...entities, period };
    } catch (error) {
      const { handleError } = options;

      handleError(error);
    }
  }
);

export const createDownloadAttachmentThunk = (typePrefix, type) => createAsyncThunk(
  typePrefix,
  async options => {
    try {
      const { accountId, period, fileName, handleProgress, params } = options;

      await downloadAttachmentRequest(accountId, period, type, fileName, handleProgress, params);
    } catch (error) {
      const { handleError } = options;

      handleError(error);
    }
  }
);

export const createGenerateAttachmentThunk = (typePrefix, type) => createAsyncThunk(
  typePrefix,
  async options => {
    try {
      const { accountId, period, fileName, handleProgress, params } = options;

      await generateAttachmentRequest(accountId, period, type, fileName, handleProgress, params);
    } catch (error) {
      const { handleError } = options;

      handleError(error);
    }
  }
);

export const createDeleteAttachmentThunk = (typePrefix, type) => createAsyncThunk(
  typePrefix,
  async options => {
    try {
      const { accountId, period } = options;

      await deleteAttachmentRequest(accountId, period, type);

      return period;
    } catch (error) {
      const { handleError } = options;

      handleError(error);
    }
  }
);

// Adapter
export const attachmentsAdapter = createEntityAdapter({
  selectId: attachment => attachment.period
});
