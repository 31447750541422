import numeral from 'numeral';
import * as PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import numberColor from '../../utils/number-color';
import { selectOwnerById } from '../owners/ownersSlice';
import { selectAccountById } from './accountsSlice';

const AccountsTableRow = ({ id }) => {
  const account = useSelector(state => selectAccountById(state, id));
  const owner = useSelector(state => selectOwnerById(state, account.owner_id));

  return (
    <tr>
      <td>
        <Link to={`/accounts/${account.id}`}>
          {'931 '}
          {account.deposit_account_number}
        </Link>
      </td>
      <td>
        <Link to={`/owners/${account.owner_id}`}>
          {owner.name}
        </Link>
      </td>
      <td className={`text-right text-${numberColor(account.balance)}`}>
        &yen;
        {numeral(account.balance).format('0,0')}
      </td>
    </tr>
  );
};

AccountsTableRow.propTypes = {
  id: PropTypes.number.isRequired
};

export default AccountsTableRow;
