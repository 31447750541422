import * as PropTypes from 'prop-types';
import React from 'react';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

const SpinnerButton = ({ loading, children, ...rest }) => (
  <Button disabled={loading} {...rest}>
    {children}
    {' '}
    {loading && (
      <Spinner
        as="span"
        animation="border"
        size="sm"
      />
    )}
  </Button>
);

SpinnerButton.propTypes = {
  loading: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default SpinnerButton;
