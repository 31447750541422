import * as PropTypes from 'prop-types';
import React from 'react';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import { useSelector } from 'react-redux';
import Loader from '../../common/Loader';
import useAsyncThunkFlagsSelector from '../../common/useAsyncThunkFlagsSelector';
import ErrorModal from '../errors/ErrorModal';
import useResetAsyncThunkErrorCallback from '../errors/useResetAsyncThunkErrorCallback';
import { fetchStatementList } from './statementsOperations';
import { selectStatementIdList } from './statementsSlice';
import StatementsTableRow from './StatementsTableRow';

const LoaderRow = () => (
  <tr>
    <td>
      <Loader length={50} />
    </td>
    <td>
      <Loader length={50} />
    </td>
    <td>
      <Loader length={50} height={2} />
    </td>
    <td>
      <Loader length={50} height={2} />
    </td>
    <td>
      <Loader length={50} height={2} />
    </td>
  </tr>
);

const StatementsListCard = ({ className }) => {
  const statementIdList = useSelector(selectStatementIdList);
  const { isLoading, error } = useAsyncThunkFlagsSelector(fetchStatementList);
  const handleErrorModalHide = useResetAsyncThunkErrorCallback(fetchStatementList);

  return (
    <Card className={className}>
      <Card.Header as="h2">期間一覧</Card.Header>
      <Card.Body>
        <Table responsive hover size="sm">
          <thead>
            <tr>
              <th>期間</th>
              <th>残高</th>
              <th>PDFファイル</th>
              <th>XLSXファイル</th>
              <th>XLSXファイル</th>
            </tr>
          </thead>

          <tbody>
            {isLoading ?
              [1, 2, 3, 4, 5].map(value =>
                <LoaderRow key={value} />
              ) :
              statementIdList.map(statementId =>
                <StatementsTableRow periodId={statementId} key={statementId} />
              )
            }
          </tbody>
        </Table>

        <ErrorModal onHide={handleErrorModalHide} error={error} />
      </Card.Body>
    </Card>
  );
};

StatementsListCard.propTypes = {
  className: PropTypes.string
};

StatementsListCard.defaultProps = {
  className: ''
};

export default StatementsListCard;
