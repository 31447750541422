import * as PropTypes from 'prop-types';
import React from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import { useSelector } from 'react-redux';
import Loader from '../../common/Loader';
import Text from '../../common/Text';
import useAsyncThunkFlagsSelector from '../../common/useAsyncThunkFlagsSelector';
import ErrorModal from '../errors/ErrorModal';
import useResetAsyncThunkErrorCallback from '../errors/useResetAsyncThunkErrorCallback';
import OwnersTableRow from './OwnersTableRow';
import { fetchOwnerList, selectOwnerIdList } from './ownersSlice';

const LoaderRow = () => (
  <tr>
    <td>
      <Loader length={50} />
    </td>
    <td>
      <Loader length={75} />
    </td>
  </tr>
);

const OwnersListCard = ({ className }) => {
  const ownerIdList = useSelector(selectOwnerIdList);
  const { isLoading, error } = useAsyncThunkFlagsSelector(fetchOwnerList);
  const handleErrorModalHide = useResetAsyncThunkErrorCallback(fetchOwnerList);

  return (
    <Card className={className}>
      <Card.Header>
        <Row>
          <Col md>
            <Text as="h2" className="mb-0">オーナー一覧</Text>
          </Col>
        </Row>
      </Card.Header>

      <Card.Body>
        <Table responsive hover size="sm">
          <thead>
            <tr>
              <th>コード</th>
              <th>名</th>
            </tr>
          </thead>

          <tbody>
            {isLoading ?
              [1, 2, 3, 4, 5].map(value =>
                <LoaderRow key={value} />
              ) :
              ownerIdList.map(ownerId =>
                <OwnersTableRow id={ownerId} key={ownerId} />
              )
            }
          </tbody>
        </Table>

        <ErrorModal onHide={handleErrorModalHide} error={error} />
      </Card.Body>
    </Card>
  );
};

OwnersListCard.propTypes = {
  className: PropTypes.string
};

OwnersListCard.defaultProps = {
  className: '',
};

export default OwnersListCard;
