import React from 'react';
import Container from 'react-bootstrap/Container';

const style = {
  footer: {
    marginTop: 'auto',
    backgroundColor: '#343a40',
    color: 'rgba(255,255,255,.5)',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
  },
};

const Footer = () => (
  <div style={style.footer}>
    <Container>
      <div style={style.content}>
        &copy;
        {' '}
        {new Date().getFullYear()}
        {' '}
        Housing Japan K.K.
      </div>
    </Container>
  </div>
);

export default Footer;
