import { createReducer } from '@reduxjs/toolkit';
import get from 'lodash.get';
import some from 'lodash.some';

function isPendingAction(action) {
  return action.type.endsWith('/pending');
}

function isFulfilledAction(action) {
  return action.type.endsWith('/fulfilled');
}

function isRejectedAction(action) {
  return action.type.endsWith('/rejected');
}

function isFulfilledOrRejectedAction(action) {
  return isFulfilledAction(action) || isRejectedAction(action);
}

// Selector returns false only when all actions is not loading
export const createLoadingSelector = actions => state => some(actions, action => get(state, `loading.${action}`));

// Reducer
export default createReducer({}, builder => {
  builder
    .addMatcher(isPendingAction, (state, action) => {
      state[action.type.replace('/pending', '')] = true;
    })
    .addMatcher(isFulfilledOrRejectedAction, (state, action) => {
      state[action.type.replace(/(\/fulfilled|\/rejected)/, '')] = false;
    });
});
