import { schema } from 'normalizr';

export const ownerSchema = new schema.Entity('Owner', {}, {
    idAttribute: 'id'
});

export const accountSchema = new schema.Entity(
    'Account',
    { owner: ownerSchema },
    { idAttribute: 'id' }
);

export const propertySchema = new schema.Entity('Property', {}, {
    idAttribute: 'code'
});

export const roomSchema = new schema.Entity('Room', {}, {
    idAttribute: value => `${value.owner_code}-${value.property_code}-${value.room_number}`
});

export const pdfSchema = new schema.Entity('Pdf', {}, {
    idAttribute: 'period'
});

export const xlsxSchema = new schema.Entity('Xlsx', {}, {
    idAttribute: 'period'
});

export const statementSchema = new schema.Entity(
    'Statement',
    {
        pdf: pdfSchema,
        xlsx: xlsxSchema
    },
    { idAttribute: 'period' }
);

export const userSettingSchema = new schema.Entity('UserSetting', {}, {
    idAttribute: 'user_id'
});

export const ownerUserSchema = new schema.Entity(
    'OwnerUser',
    { owner: ownerSchema, user: userSettingSchema },
    { idAttribute: value => `${value.owner_id}-${value.user_id}` }
);
