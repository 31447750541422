import AccountsDetailPage from '../features/accounts/AccountsDetailPage';
import AccountsSearchPage from '../features/accounts/AccountsSearchPage';
import LoginPage from '../features/authentication/LoginPage';
import OwnersDetailPage from '../features/owners/OwnersDetailPage';
import OwnersSearchPage from '../features/owners/OwnersSearchPage';
import PropertiesDetailsPage from '../features/properties/DetailsPage';
import PropertiesIndexPage from '../features/properties/IndexPage';

const routes = [
  {
    path: '/login',
    exact: true,
    withHeader: false,
    component: LoginPage,
  },
  // {
  //     path: "/access-denied",
  //     exact: true,
  //     layout: BlankLayout,
  //     component: AccessDeniedContainer
  // },
  {
    path: '/',
    exact: true,
    withHeader: true,
    component: OwnersSearchPage,
    isPrivate: true,
  },
  {
    path: '/accounts',
    exact: true,
    withHeader: true,
    component: AccountsSearchPage,
    isPrivate: true,
  },
  {
    path: '/accounts/:id',
    exact: true,
    withHeader: true,
    component: AccountsDetailPage,
    isPrivate: true,
  },
  {
    path: '/owners',
    exact: true,
    withHeader: true,
    component: OwnersSearchPage,
    isPrivate: true,
  },
  {
    path: '/owners/:id',
    exact: true,
    withHeader: true,
    component: OwnersDetailPage,
    isPrivate: true,
  },
  {
    path: '/properties',
    exact: true,
    withHeader: true,
    component: PropertiesIndexPage,
    isPrivate: true,
  },
  {
    path: '/properties/:code',
    exact: true,
    withHeader: true,
    component: PropertiesDetailsPage,
    isPrivate: true,
  },
];

export default routes;
