import { useMemo } from 'react';

const processValidationError = error => {
  let content = [];

  error.fieldList.forEach(field => {
    content.push(`${field.name}: ${field.message}`);
  });

  return {
    title: 'Validation Error',
    content
  };
};

const processConflictError = error => {
  const { name, message } = error.field;
  let content = '';

  if (name === 'owner_user') {
    if (message === 'linked') {
      content = 'User already registered to this owner.';
    }
  }

  return {
    title: 'Conflict Error',
    content
  };
};

export const processError = error => {
  if (error) {
    switch (error.name) {
      case 'ValidationError':
        return processValidationError(error);
      case 'ConflictError':
        return processConflictError(error);
      case 'SimpleError':
        return {
          title: error.title,
          content: error.content
        };
      default:
        return {
          title: 'Error',
          content: 'Something went wrong.'
        };
    }
  }

  return null;
};

const useProcessedError = error => useMemo(() => processError(error), [error]);

export default useProcessedError;
