import { createAction, createReducer } from '@reduxjs/toolkit';

function isPendingAction(action) {
  return action.type.endsWith('/pending');
}

function isRejectedAction(action) {
  return action.type.endsWith('/rejected');
}

export const resetOperationError = createAction('error/resetOperationError');

// Returns the current error value for action
export const createErrorSelector = function (action) {
  return state => state.error[action] ? state.error[action] : null;
}

// Reducer
export default createReducer({}, builder => {
  builder
    .addCase(resetOperationError, (state, action) => {
      state[action.payload] = null;
    })
    .addMatcher(isPendingAction, (state, action) => {
      state[action.type.replace('/pending', '')] = null;
    })
    .addMatcher(isRejectedAction, (state, action) => {
      state[action.type.replace('/rejected', '')] = action.payload;
    });
});
