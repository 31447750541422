import { createSlice } from '@reduxjs/toolkit';
import { xlsxSchema } from '../../app/schema';
import { fetchStatementList } from "../statements/statementsOperations";
import { attachmentsAdapter as xlsxsAdafter, createDeleteAttachmentThunk, createDownloadAttachmentThunk, createUploadAttachmentThunk, createGenerateAttachmentThunk } from './attachmentsOperations';

// Operations
export const uploadXlsxAttachment = createUploadAttachmentThunk('xlsxs/uploadXlsxAttachment', 'xlsx', xlsxSchema);

export const downloadXlsxAttachment = createDownloadAttachmentThunk('xlsxs/downloadXlsxAttachment', 'xlsx');

export const generateXlsxAttachment = createGenerateAttachmentThunk('xlsxs/generateXlsxAttachment', 'xlsx');

export const deleteXlsxAttachment = createDeleteAttachmentThunk('xlsxs/deleteXlsxAttachment', 'xlsx');

// Slice
const xlsxsSlice = createSlice({
  name: 'xlsxs',
  initialState: xlsxsAdafter.getInitialState(),
  extraReducers: builder => {
    builder
      .addCase(uploadXlsxAttachment.fulfilled, (state, action) => {
        xlsxsAdafter.addMany(state, action.payload.Xlsx);
      })
      .addCase(deleteXlsxAttachment.fulfilled, (state, action) => {
        xlsxsAdafter.removeOne(state, action.payload);
      })
      .addCase(fetchStatementList.fulfilled, (state, action) => {
        if (action.payload.Xlsx) {
          xlsxsAdafter.setAll(state, action.payload.Xlsx);
        } else {
          xlsxsAdafter.removeAll(state);
        }
      });
  }
});

// Selector
export const { selectById: selectXlsxByPeriod } = xlsxsAdafter.getSelectors(state => state.attachments.xlsxs);

// Reducer
export default xlsxsSlice.reducer;
