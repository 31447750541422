const numberColor = number => {
  if (number > 0) {
    return 'success';
  } else if (number < 0) {
    return 'danger';
  } else {
    return '';
  }
};

export default numberColor;
