import * as PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AttachmentCell from './AttachmentCell';
import { deletePdfAttachment, downloadPdfAttachment, selectPdfByPeriod, uploadPdfAttachment } from './pdfsSlice';

const PdfAttachmentCell = ({ accountId, ownerId, period, pdfPeriod }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [progressValue, setProgressValue] = useState(0);
  const pdf = useSelector(state => selectPdfByPeriod(state, pdfPeriod));
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoading && pdfPeriod && progressValue === 100) {
      setIsLoading(false);
      setProgressValue(0);
    }
  }, [pdfPeriod, isLoading, progressValue]);

  const handleProgress = progressEvent => {
    const value = (progressEvent.loaded / progressEvent.total) * 100;
    setProgressValue(value);
  };

  const handleError = error => {
    setError(error);
  };

  const handleUploadFile = useCallback(formData => {
    setIsLoading(true);
    dispatch(uploadPdfAttachment({
      accountId,
      period,
      formData,
      handleProgress,
      handleError,
    }));
  }, [dispatch, accountId, period]);

  const handleDownloadFile = useCallback(() => {
    setIsLoading(true);
    dispatch(downloadPdfAttachment({
      accountId,
      period,
      fileName: pdf.filename,
      handleProgress,
      handleError,
      params: {
        owner_id: ownerId
      }
    }));
  }, [dispatch, accountId, ownerId, period, pdf]);

  const handleDeleteClick = useCallback(() => {
    dispatch(deletePdfAttachment({
      accountId,
      period,
      handleError
    }));
  }, [dispatch, accountId, period]);

  const handleErrorModalHide = useCallback(() => {
    setError(false);
  }, []);

  return (
    <AttachmentCell
      attachmentType="pdf"
      isLoading={isLoading}
      progressValue={progressValue}
      onUpload={handleUploadFile}
      onDownloadClick={handleDownloadFile}
      onDeleteClick={handleDeleteClick}
      attachment={pdf}
      onErrorModalHide={handleErrorModalHide}
      error={error}
    />
  );
};

PdfAttachmentCell.propTypes = {
  ownerId: PropTypes.number.isRequired,
  accountId: PropTypes.number.isRequired,
  period: PropTypes.string.isRequired,
  pdfPeriod: PropTypes.string
};

PdfAttachmentCell.defaultProps = {
  pdfPeriod: null
};

export default PdfAttachmentCell;