import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import SpinnerButton from '../../common/SpinnerButton';
import Text from '../../common/Text';
import useDocumentTitle from '../../common/useDocumentTitle';
import logo from '../../images/logo_hj.svg';
import { login, selectSession, selectSessionIsAuthenticating, setRedirect } from '../session/sessionSlice';

const style = {
  container: {
    maxWidth: 400,
  },
  logo: {
    width: 180,
    height: 180,
  },
};

const LoginPage = () => {
  useDocumentTitle('');
  const {
    isAuthenticated,
    error,
    redirectUrl
  } = useSelector(selectSession);
  const isAuthenticating = useSelector(selectSessionIsAuthenticating);
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (location.state && location.state.redirect) {
      dispatch(setRedirect(location.state.from.pathname));
    } else {
      dispatch(setRedirect(null));
    }
  }, [location.state, dispatch]);

  const handleLogin = values => {
    dispatch(login(values));
  };

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    onSubmit: handleLogin
  });

  if (isAuthenticated && !error) {
    if (redirectUrl) {
      return (
        <Redirect to={{ pathname: redirectUrl, state: { from: location } }} />
      );
    }

    return (
      <Redirect to={{ pathname: '/', state: { from: location } }} />
    );
  }

  return (
    <div className="mx-auto mt-5" style={style.container}>
      <div className="text-center mb-3">
        <Image src={logo} style={style.logo} />
      </div>

      <Text as="h1" align="center">オーナーの管理ページ</Text>

      <Form onSubmit={formik.handleSubmit}>
        <Form.Group>
          <Form.Control
            name="username"
            type="text"
            placeholder="メールアドレス"
            autoComplete="username"
            required
            value={formik.values.username}
            onChange={formik.handleChange}
          />
        </Form.Group>

        <Form.Group>
          <Form.Control
            name="password"
            type="password"
            placeholder="パスワード"
            autoComplete="current-password"
            required
            value={formik.values.password}
            onChange={formik.handleChange}
          />
        </Form.Group>

        {error === 'generic' && (
          <Alert variant="danger">
            <Alert.Heading>ログイン失敗</Alert.Heading>
            <p>不明なエラーが発生しました。もう一度やり直してください。</p>
          </Alert>
        )}

        {error === 'invalid_credentials' && (
          <Alert variant="danger">
            <Alert.Heading>ログイン失敗</Alert.Heading>
            <p>メールアドレスまたはパスワードが間違っています。</p>
          </Alert>
        )}

        <SpinnerButton
          loading={isAuthenticating}
          type="submit"
          variant="primary"
          block
        >
          ログイン
        </SpinnerButton>
      </Form>
    </div>
  );
};

export default LoginPage;
