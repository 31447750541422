import authRequest from '../../utils/request';
import { options } from '../../utils/token';

export const fetchInfo = async () => {
  const data = await authRequest({ url: `${options.url}/me` });

  return {
    info: data.info,
    permissions: data.permissions,
    preferences: data.preferences,
  };
};
