import { createSlice } from '@reduxjs/toolkit';
import { pdfSchema } from '../../app/schema';
import { fetchStatementList } from "../statements/statementsOperations";
import { attachmentsAdapter as pdfsAdafter, createDeleteAttachmentThunk, createDownloadAttachmentThunk, createUploadAttachmentThunk } from './attachmentsOperations';

// Operations
export const uploadPdfAttachment = createUploadAttachmentThunk('pdfs/uploadPdfAttachment', 'pdf', pdfSchema);

export const downloadPdfAttachment = createDownloadAttachmentThunk('pdfs/downloadPdfAttachment', 'pdf');

export const deletePdfAttachment = createDeleteAttachmentThunk('pdfs/deletePdfAttachment', 'pdf');

// Slice
const pdfsSlice = createSlice({
  name: 'pdfs',
  initialState: pdfsAdafter.getInitialState(),
  extraReducers: builder => {
    builder
      .addCase(uploadPdfAttachment.fulfilled, (state, action) => {
        pdfsAdafter.addMany(state, action.payload.Pdf);
      })
      .addCase(deletePdfAttachment.fulfilled, (state, action) => {
        pdfsAdafter.removeOne(state, action.payload);
      })
      .addCase(fetchStatementList.fulfilled, (state, action) => {
        if (action.payload.Pdf) {
          pdfsAdafter.setAll(state, action.payload.Pdf);
        } else {
          pdfsAdafter.removeAll(state);
        }
      });
  }
});

// Selector
export const { selectById: selectPdfByPeriod } = pdfsAdafter.getSelectors(state => state.attachments.pdfs);

// Reducer
export default pdfsSlice.reducer;
