import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { resetOperationError } from './errorSlice';

const useResetAsyncThunkErrorCallback = operation => {
  const dispatch = useDispatch();

  return useCallback(() => {
    dispatch(resetOperationError(operation.typePrefix));
  }, [dispatch, operation]);
};

export default useResetAsyncThunkErrorCallback;
