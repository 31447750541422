import { createAsyncThunk } from '@reduxjs/toolkit';
import { normalize } from 'normalizr';
import { statementSchema } from '../../app/schema';
import { fetchStatementListRequest } from './statementsApi';

// Operation
export const fetchStatementList = createAsyncThunk(
  'statements/fetchStatementList',
  async (options, { rejectWithValue }) => {
    try {
      const { accountId = 0, query = {} } = options;

      const statementList = await fetchStatementListRequest({
        accountId,
        query,
      });

      const { entities } = normalize(statementList, [statementSchema]);

      return entities;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
