import * as PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectOwnerById } from './ownersSlice';

const OwnersTableRow = ({ id }) => {
    const owner = useSelector(state => selectOwnerById(state, id));

    return (
        <tr>
            <td>
                <Link to={`/owners/${owner.id}`}>{owner.id}</Link>
            </td>
            <td>
                <Link to={`/owners/${owner.id}`}>{owner.name}</Link>
            </td>
        </tr>
    );
};

OwnersTableRow.propTypes = {
    id: PropTypes.number.isRequired
};

export default OwnersTableRow;
