import * as PropTypes from 'prop-types';
import React from 'react';
import Container from 'react-bootstrap/Container';
import { Route } from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';

const style = {
  container: {
    paddingTop: 70,
  },
};

const Layout = ({ component: Component, withHeader, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      <>
        {withHeader && <Header />}

        <Container style={withHeader ? style.container : {}}>
          <Component {...props} />
        </Container>

        <Footer />
      </>
    )}
  />
);

Layout.propTypes = {
  component: PropTypes.elementType.isRequired,
  withHeader: PropTypes.bool,
};

Layout.defaultProps = {
  withHeader: false,
};

export default Layout;
