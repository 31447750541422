import { useFormik } from 'formik';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useDispatch } from 'react-redux';
import SpinnerButton from '../../common/SpinnerButton';
import useAsyncThunkFlagsSelector from '../../common/useAsyncThunkFlagsSelector';
import { fetchOwnerList } from './ownersSlice';

const OwnersSearchForm = () => {
  const dispatch = useDispatch();
  const { isLoading } = useAsyncThunkFlagsSelector(fetchOwnerList);

  const handleSearch = query => {
    dispatch(fetchOwnerList({ query }));
  };

  const formik = useFormik({
    initialValues: {
      id: '',
      name: ''
    },
    onSubmit: handleSearch
  });

  return (
    <Form autoComplete="off" onSubmit={formik.handleSubmit}>

      <Row>
        <Col>
          <Form.Group as={Row} controlId="field-id">
            <Form.Label column sm="4" className="text-right">コード</Form.Label>
            <Col>
              <Form.Control
                name="id"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.id}
              />
            </Col>
          </Form.Group>
        </Col>

        <Col>
          <Form.Group as={Row} controlId="field-name">
            <Form.Label column sm="4" className="text-right">名</Form.Label>
            <Col>
              <Form.Control
                name="name"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.name}
              />
            </Col>
          </Form.Group>
        </Col>
      </Row>

      <Row className="justify-content-end">
        <Col xs="auto">
          <SpinnerButton
            loading={isLoading}
            type="submit"
            variant="primary"
          >
            検索
          </SpinnerButton>
        </Col>
      </Row>
    </Form>
  );
};

export default OwnersSearchForm;
