import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Loader from '../../common/Loader';
import Text from '../../common/Text';
import useAsyncThunkFlagsSelector from '../../common/useAsyncThunkFlagsSelector';
import ErrorModal from '../errors/ErrorModal';
import useResetAsyncThunkErrorCallback from '../errors/useResetAsyncThunkErrorCallback';
import StatementsListCard from '../statements/StatementsListCard';
import { fetchStatementList } from "../statements/statementsOperations";
import AccountsDetailCard from './AccountsDetailCard';
import { fetchAccount, selectAccountById } from './accountsSlice';

const AccountsDetailPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const account = useSelector(state => selectAccountById(state, id));
  const { isLoading, error } = useAsyncThunkFlagsSelector(fetchAccount);
  const handleErrorModalHide = useResetAsyncThunkErrorCallback(fetchAccount);
  let title;

  useEffect(() => {
    dispatch(fetchAccount({
      id,
    }));
  }, [id, dispatch]);

  useEffect(() => {
    if (account) {
      dispatch(fetchStatementList({
        accountId: id,
        query: { owner_id: account.owner_id, inclusions: ['attachment'] }
      }));
    }
  }, [id, account, dispatch]);

  if (isLoading || !account) {
    title = <Loader height={2.5} length={50} />;
  } else {
    title = (
      <Text as="h1">
        {`931 ${account.deposit_account_number}`}
      </Text>
    );
  }

  return (
    <>
      {title}

      <hr />

      <AccountsDetailCard className="mb-3" />

      <StatementsListCard className="mb-3" />

      <ErrorModal onHide={handleErrorModalHide} error={error} />
    </>
  );
};

export default AccountsDetailPage;
