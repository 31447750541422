import * as PropTypes from 'prop-types';
import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Text from './Text';

const DeleteModal = ({ show, onDeleteClick, onCancelClick }) => (
  <Modal show={show} onHide={onCancelClick}>
    <Modal.Header closeButton>
      <Modal.Title>削除確認</Modal.Title>
    </Modal.Header>

    <Modal.Body>
      <Text>このデータを削除してもよろしいですか？</Text>
    </Modal.Body>

    <Modal.Footer>
      <Button variant="secondary" onClick={onCancelClick}>キャンセル</Button>

      <Button variant="danger" onClick={onDeleteClick}>削除</Button>
    </Modal.Footer>
  </Modal>
);

DeleteModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired
};

export default DeleteModal;
