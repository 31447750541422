import * as PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import Layout from '../features/layout/Layout';
import { selectSessionIsAuthenticated, selectSessionUser } from '../features/session/sessionSlice';

const PrivateRoute = ({
  component: Component, withHeader, ...rest
}) => {
  const isAuthenticated = useSelector(selectSessionIsAuthenticated);
  const User = useSelector(selectSessionUser);

  return (
    <Route
      {...rest}
      render={routeProps => {
        if (isAuthenticated) {
          // If user is NOT allowed access
          if (User.permissions.indexOf('ownr:admin') === -1) {
            return <Redirect to={{ pathname: '/access-denied', state: { from: routeProps.location } }} />;
          }

          return <Layout {...routeProps} component={Component} withHeader={withHeader} />;
        } else {
          return <Redirect to={{ pathname: '/login', state: { from: routeProps.location, redirect: true } }} />;
        }
      }}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  withHeader: PropTypes.bool,
};

PrivateRoute.defaultProps = {
  withHeader: false,
};

export default PrivateRoute;
