import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Loader from '../../common/Loader';
import Text from '../../common/Text';
import useAsyncThunkFlagsSelector from '../../common/useAsyncThunkFlagsSelector';
import ErrorModal from '../errors/ErrorModal';
import useResetAsyncThunkErrorCallback from '../errors/useResetAsyncThunkErrorCallback';
import RoomListCard from '../rooms/ListCard';
import { fetchRoomList } from '../rooms/slice';
import DetailsCard from './DetailsCard';
import { fetchProperty, selectPropertyByCode } from './slice';

const DetailsPage = () => {
  const { code } = useParams();
  const dispatch = useDispatch();
  const property = useSelector(state => selectPropertyByCode(state, code));
  const { isLoading, error } = useAsyncThunkFlagsSelector(fetchProperty);
  const handleErrorModalHide = useResetAsyncThunkErrorCallback(fetchProperty);
  let title;

  useEffect(() => {
    dispatch(fetchProperty({
      code
    }));

    dispatch(fetchRoomList({
      params: {
        property_code: code
      }
    }));
  }, [code, dispatch]);

  if (isLoading || !property) {
    title = <Loader height={2.5} length={50} />;
  } else {
    title = (
      <Text as="h1">{property.name}</Text>
    );
  }

  return (
    <>
      {title}

      <hr />

      <DetailsCard className="mb-3" />

      <RoomListCard className="mb-3" />

      <ErrorModal onHide={handleErrorModalHide} error={error} />
    </>
  );
};

export default DetailsPage;
