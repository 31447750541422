import * as PropTypes from 'prop-types';
import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import { logout } from '../session/sessionSlice';

const LogoutModal = ({ isShown, onHide }) => {
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <Modal centered show={isShown} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>ログアウト</Modal.Title>
      </Modal.Header>

      <Modal.Body>本当にログアウトしますか？</Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          戻る
      </Button>
        <Button variant="primary" onClick={handleLogout}>
          ログアウト
      </Button>
      </Modal.Footer>
    </Modal>
  );
};

LogoutModal.propTypes = {
  isShown: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired
};

export default LogoutModal;
