import axios from 'axios';
import history from '../app/history';

export const options = {
  url: process.env.AUTH_URL,
  client_id: process.env.AUTH_CLIENT_ID,
  client_secret: process.env.AUTH_CLIENT_SECRET,
  token_name: process.env.AUTH_TOKEN_NAME,
};

class OAuth2Error extends Error {
  constructor(message, error) {
    // Call Error class contructor
    super(message);
    this.name = 'OAuth2Error';

    // Assign code property
    this.error = error;
  }
}

const fetchToken = async (configuration) => {
  try {
    const { data: token } = await axios({
      method: 'POST',
      url: `${options.url}/access_token`,
      data: new URLSearchParams({
        ...configuration,
        client_id: options.client_id,
        client_secret: options.client_secret,
      }).toString(),
    });

    // Set token timestamp
    token.timestamp = Math.floor(Date.now() / 1000);

    // Store access token in localStorage
    setToken(token);

    // Return token
    return token;
  } catch (error) {
    if (error.response) {
      const errorData = error.response.data;

      // throw OAuth2Error
      throw new OAuth2Error(errorData.message, errorData.error);
    } else if (error.request) {
      // Log error message
      console.error(error.message);

      // Throw new error
      throw new Error('Unable to fetch token');
    } else {
      throw new Error(error);
    }
  }
};

export const authenticate = (username, password) => fetchToken({
  grant_type: 'password',
  username,
  password,
});

export const getToken = () => {
  return JSON.parse(localStorage.getItem(options.token_name));
};

export const refreshToken = () => {
  return fetchToken({
    grant_type: 'refresh_token',
    refresh_token: getToken().refresh_token,
  }).catch(err => {
    removeToken();

    history.push('/login');

    throw err;
  });
};

export const setToken = token => {
  localStorage.setItem(options.token_name, JSON.stringify(token));
};

export const removeToken = () => {
  localStorage.removeItem(options.token_name);
};
